import { Component, OnInit } from '@angular/core';
import { Auth } from 'aws-amplify';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
      Auth.signOut()
      localStorage.clear();
      window.location.replace(environment.signOut);
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponentComponent } from '@shared/loading-component/loading-component.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    LoadingComponentComponent
  ],
  declarations: [
    LoadingComponentComponent
  ]
  
})
export class SharedModule { }

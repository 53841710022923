import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Hub, Auth } from 'aws-amplify';
import { environment } from '@environments/environment';
import { AuthService } from '@shared/services/guards/auth.service';
import { RouteService } from '@shared/services/guards/route.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loading = false;
  constructor(
    private router: Router,
    private routeService: RouteService,
    private authService: AuthService,
    private ngZone: NgZone,
  ){}

  ngOnInit() {
    if(this.routeService.getPreviousUrl() == '/login'){
      this.routing()
    }else{
      this.loading = true;
      Hub.listen('auth', (data) => {
        switch (data.payload.event) {
            case 'signIn':
                this.routing()
                break;
            default:
                break;
        }
      });
    }
  }

  routing() {
      this.authService.isVerified().subscribe(data => {
        this.update(true,'')
      },
      err => {
        this.update(false, '')
      }
    );
  }

  update(isAuthenticated, userType){
    Auth.currentSession().then( data => {
      if (isAuthenticated){
        this.ngZone.run(() => this.router.navigate([environment.team_upload]))
      }
      else {
        // CLEAR STORAGE AND REDIRECT
        Auth.signOut()
        localStorage.clear();
        window.location.replace(environment.signOut);
      }
    }) 
  }
}

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from "ngx-toastr";

import { FooterModule } from './shared/footer/footer.module';
import { FixedPluginModule} from './shared/fixedplugin/fixedplugin.module';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { ConfirmComponent } from './shared/modals/confirm/confirm.component';
import { InformationComponent } from './shared/modals/information/information.component';
import { TimedComponent } from './shared/modals/timed/timed.component';
import { SchedInputComponent } from './shared/modals/sched-input/sched-input.component';
import { LoginComponent } from '@core/login/login.component';
import { SharedModule } from '@shared/shared.module';

import { AuthGuard } from '@shared/services/guards/auth-guard.service';
import { RoleGuard } from '@app/shared/services/guards/role-guard.service';
import { CanDeactivateGuard } from '@app/shared/services/guards/deactivate-guard.service';
import { LogoutComponent } from './core/logout/logout.component';

import { AuthInterceptor } from '../app/shared/services/guards/auth.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    ConfirmComponent,
    SchedInputComponent,
    InformationComponent,
    TimedComponent,
    LoginComponent,
    LogoutComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes),
    ToastrModule.forRoot(),
    FooterModule,
    FixedPluginModule,
    HttpClientModule,
    SharedModule,
    FormsModule
  ],
  providers: [
    AuthGuard,
    RoleGuard,
    CanDeactivateGuard,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AuthInterceptor,
    //   multi:true
    // }
  ],
  bootstrap: [AppComponent],
  entryComponents: [InformationComponent, ConfirmComponent, SchedInputComponent, TimedComponent]
})
export class AppModule { }

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:93b0881a-4701-496d-ba1a-33aa0eea21b5",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_b36tTM4za",
    "aws_user_pools_web_client_id": "6le4d8i4itfebsquhki8bgcjku",
    "oauth": {},
    "aws_content_delivery_bucket": "teamuploadfe-20200910112605-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "ap-southeast-1",
    "aws_content_delivery_url": "https://d37g2ipgzw6wok.cloudfront.net"
};


export default awsmobile;

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
// signIn: this.domainName + `/login?response_type=` + this.code + `&client_id=`+ this.clientId +`&redirect_uri=` + this.redirectUri,

const signInConfig = {
  WEB_PROTOCOL:'https://',
  DOMAIN_NAME: 'teamupload-dev.auth.ap-southeast-1.amazoncognito.com',
  CLIENT_ID: '6le4d8i4itfebsquhki8bgcjku',
  RESPONSE_TYPE: 'code',  // or 'token', note that REFRESH token will only be generated when the responseType is code
  REDIRECT_SIGN_IN: 'https://codelight-upload.meco-soldel.com/login',
  REDIRECT_SIGN_OUT: 'https://codelight-upload.meco-soldel.com',
  SCOPE: ['email', 'openid'],
}

export const environment = {
  production: false,

  // DEFAULT PAGES
  team_upload: '/team-upload',

  // API FOR RAPID TESTING - NEO4J
  hostURL: 'https://9auw26440d.execute-api.ap-southeast-1.amazonaws.com/production',

  // AMPLIFY environments
  domainName: `${signInConfig.DOMAIN_NAME}`,
  clientId: `${signInConfig.CLIENT_ID}`,
  responseType: `${signInConfig.RESPONSE_TYPE}`,
  redirectUri: `${signInConfig.REDIRECT_SIGN_IN}`,
  redirectSignIn: `${signInConfig.REDIRECT_SIGN_IN}`,
  redirectSignOut: `${signInConfig.REDIRECT_SIGN_OUT}`,
  scope: `${signInConfig.SCOPE}`,
  signIn: `${signInConfig.WEB_PROTOCOL}${signInConfig.DOMAIN_NAME}/login?client_id=${signInConfig.CLIENT_ID}&response_type=${signInConfig.RESPONSE_TYPE}&scope=email+openid&redirect_uri=${signInConfig.REDIRECT_SIGN_IN}`,
  signOut: `${signInConfig.WEB_PROTOCOL}${signInConfig.DOMAIN_NAME}/logout?client_id=${signInConfig.CLIENT_ID}&logout_uri=${signInConfig.REDIRECT_SIGN_OUT}`

};
// https://cbm-admin-dev.auth.ap-southeast-1.amazoncognito.com/login?client_id=4efofql74344qlibvqoag249d4&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=https://dev.cbm-admin.meco-soldel.com/login
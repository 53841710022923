import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sched-input',
  templateUrl: './sched-input.component.html',
  styleUrls: ['./sched-input.component.scss']
})
export class SchedInputComponent implements OnInit {
  @Input() title: string;
  @Input() information: string;
  @Input() positiveButton: string;
  @Input() negativeButton: string;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  companyName:string
  contactInfo: string;
  errorMessage = 'Company and Contact Information are required fields.'
  err = false;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  passBack() {
    if ((this.companyName == undefined || this.companyName == '') || (this.contactInfo == undefined || this.contactInfo == '')) {
      this.err = true;
    } else {
      this.activeModal.close({company: this.companyName, contact_information: this.contactInfo})
      this.err = false;
    }
  }

}

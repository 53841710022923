import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';

@Injectable()
export class RoleGuard implements CanActivate {

  route: any;
  constructor(
    private router:Router
    ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    // let userType = localStorage.getItem('role').replace(/\s/g, "")
    let userType = 'ADMIN'
    let roles = route.data["roles"];
    let currentURL = state.url
    // console.log(route)

    // if yung list of roles may nag equal sa userType
    if(roles.indexOf(userType) >= 0){
      return true;
    }
    return false;
  } 
}